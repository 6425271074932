.Footer {
  bottom: 0;
  background: #820E1D;
  padding: 24px 0;
}
.Footer__text {
  color: #ffffff;
}
.Footer__link {
  text-decoration: underline;
  margin-left: 8px;
}
.Footer__link,
.Footer__link:active,
.Footer__link:hover,
.Footer__link:visited {
  color: #ffffff;
}

.Footer__link:active,
.Footer__link:hover,
.Footer__link:visited {
  text-decoration: none;
}