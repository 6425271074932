.Header {
  display: block;
  background: #820E1D;
  padding: 8px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  opacity: 0.9;
}

.Header__container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.Header__heading {
  color: #ffffff;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.Header__heading > a {
  text-decoration: none;
  color: #ffffff;
}
.Header__heading--secondary {
  font-size: 1.2rem;
  font-family: 'ubuntuitalic', sans-serif;
}

.Header__navigation {
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #ffffff;
}
.Header__navigation-item {
  display: inline-block;
  margin-right: 8px;
}
.Header__navigation-link,
.Header__navigation-link:hover,
.Header__navigation-link:focus,
.Header__navigation-link:active {
  color: #ffffff;
}

@media (min-width: 1024px){
  .Header {
    padding: 0;
  }

  .Header__container {
    flex-direction: row;
    align-items: stretch;
    padding: 0;
    width: 1200px;
    width: 90vw;
    max-width: 1200px;
    margin: 0 auto;
  }
  .Header__wrapper {
    padding: 8px;
  }

  .Header__navigation {
    border: none;
    margin: 0;
    padding: 0;
  }
  .Header__navigation-item {
    margin: 0;
  }
  .Header__navigation-link {
    background-color: #ffffff;
    color: #820E1D !important;
    display: block;
    padding: 30px;
    text-decoration: none;
    box-sizing: border-box;
    border-right: 1px solid #820E1D;
    transition: background-color 0.25s ease;
  }

  .Header__navigation-link:hover,
  .Header__navigation-link.active
  {
    color: #ffffff !important;
    background-color: #820E1D;
  }

  .Header__navigation-link:first-child:hover,
  .Header__navigation-link:first-child.active
  {
    border-left: 1px solid #ffffff;
  }

  .Header__navigation-link:last-child:hover,
  .Header__navigation-link:last-child.active
  {
    border-right: 1px solid #ffffff;
  }
}