/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* FONTS */
@font-face {
  font-family: 'ubunturegular';
  src: url('./fonts/ubuntu-regular-webfont.woff2') format('woff2'),
  url('./fonts/ubuntu-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ubuntuitalic';
  src: url('./fonts/ubuntu-italic-webfont.woff2') format('woff2'),
  url('./fonts/ubuntu-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ubuntubold';
  src: url('./fonts/ubuntu-bold-webfont.woff2') format('woff2'),
  url('./fonts/ubuntu-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

body {
  background: #eee;
}

body, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'ubunturegular', sans-serif;
}

em, i {
  font-family: 'ubuntuitalic', sans-serif;
}

strong {
  font-family: 'ubuntubold', sans-serif;
}

cite {
  font-family: 'ubuntuitalic', sans-serif;
  display: block;
  font-size: 1.5rem;
  line-height: 1.2;
  text-align: center;
  margin: 16px 0;
}

cite > small {
  font-family: 'ubunturegular', sans-serif;
  font-size: 1rem;
  display: block;
  margin-top: 8px;
}

/* CUSTOM STYLE */
.main {
  padding-top: 150px;
}

.container {
  display: block;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
}
.aside__item {
  margin-bottom: 16px;
}

.aside__item,
.container__item {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 1px 1px 1px 1px #cccccc;
}

.section {
  padding: 16px 0 0;
}

.section:last-child {
  padding-bottom: 16px;
}

.rich h1,
.heading1 {
  font-size: 2rem;
  margin: 1rem 0;
  line-height: 2.5rem;
}

.rich h2,
.heading2 {
  font-size: 1.6rem;
  margin: 1rem 0;
  line-height: 2rem;
}


.rich h3,
.heading3 {
  font-size: 1rem;
  margin: 1rem 0;
  line-height: 2rem;
}

.rich p,
.paragraph {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 8px;
}

.rich p:last-child::after,
.paragraph:last-child::after {
  content: "";
  display: table;
  clear: both;
}

.paragraph--small,
.rich p > small {
  font-size: .9rem;
}

.paragraph--alert {
  color: #820E1D;
  font-weight: 600;
}

.rich a,
.link {
  text-decoration: none;
}

.rich a:not([role="button"]),
.link {
  color: #820E1D;
}

.rich a:not([role="button"]):hover,
.rich a:not([role="button"]):active,
.rich a:not([role="button"]):focus,
.link:hover,
.link:active,
.link:focus {
  color: #690E19;
}

.rich ul,
.list {
  margin-bottom: 8px;
  padding-left: 24px;
}

.rich li,
.list__item {
  font-size: 1.2rem;
  line-height: 1.8;
  list-style: disc;
}

.teaser-image {
  width: 100%;
  float: left;
  margin: 0 8px 8px 0;
  border: 1px solid #eeeeee;
  padding: 2px;
  background-color: #ffffff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.clearfix {
  clear: both;
}

.parrallax {
  display: none;
}

.form__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #ffffff;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 8px;
}

.form__input:focus {
  border-color: #690E19;
  outline: 0;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

.button {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.button:hover,
.button:focus {
  text-decoration: none;
  outline: none;
}

.button--primary {
  color: #ffffff;
  background-color: #820E1D;
  border-color: #820E1D;
}

.button--primary:hover {
  background-color: #690E19;
  border-color: #690E19;
}

.button--secondary {
  background-color: #ffffff;
  color: #820E1D;
  border-color: #820E1D;
}

.button--secondary:hover {
  background-color: #ffffff;
  border-color: #690E19;
}

.table {
  width: 100%;
  margin-bottom: 8px;
}

.table__cell {
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
}

.table__cell--primary {
  font-weight: 600;
}

.hidden {
  display: none;
  height: 0;
}

.clearfix {
  clear: both;
}

@media (min-width: 1024px){
  .main {
    padding-top: 0;
    width: 66%;
    margin-right: 16px;
  }

  .aside {
    float: right;
    flex: 1;
  }

  .container {
    display: flex;
    padding: 0;
    width: 1200px;
    width: 90vw;
    max-width: 1200px;
    margin: 0 auto;
  }
  .aside__item,
  .container__item {
    padding: 16px;
  }

  .aside,
  .section {
    padding: 12px 0;
  }

  .aside__item:last-child,
  .section:last-child {
    padding-bottom: 12px;
  }
  .teaser-image {
    max-width: 300px;
  }
  .parrallax {
    display: block;
    height: 600px;
    height: calc(100vh - 150px);
    max-height: 600px;
  }

  .table__cell--primary {
    width: 30%;
  }

}

.paragraph__cite {
  text-align: center;
  font-weight: 600;
}

.paragraph__cite > cite {
  font-weight: 200;
  margin-top: 8px;
  font-size: 1.2rem;
}
